:root {
  --main-street-yellow: #ffc729;
  --light-gray: #f0f2f5,
}

.ant-layout-content {
  background-color: #f0f2f5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* LAYOUT */

.ant-layout-sider {
  position: absolute;
  z-index: 4;
  height: 100%;
}

.ant-layout {
  background: white;
}

.ant-layout-header {
  height: 50px;
  line-height: 48px;
  background-color: #fff;
  padding: 0px 0px 0px 15px;
  text-align: right;
}

.ant-layout-sider {
  background: var(--nav-blue);
} 

.ant-layout-header>* {
  vertical-align: middle;
  margin-right: 15px;
}

/* ANT MENU */

.ant-menu {
  background: var(--nav-blue);
  border: none;
}

.ant-menu-item {
  font-weight: 600;
  color: var(--nav-text);
}

.ant-menu-item > a {
  font-weight: 600;
  color: var(--nav-text);
}

.ant-menu-item-active:not(.ant-menu-item-selected) svg {
  fill: var(--ant-blue);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  color: var(--main-street-yellow);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a .customNavIcon {
  fill: var(--main-street-yellow);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:after {
  display: none;
}

.ant-typography {
  color: var(--main-blue);
}

.ant-menu-submenu-title {
  font-weight: 600;
  color: var(--nav-text);
}

.ant-menu-submenu > .ant-menu { 
  background: var(--nav-blue);
} 

.location-nav-text {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

@media only screen and (max-width: 768px) {
  .ant-layout-sider-zero-width-trigger {
    z-index: 1;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    top: 5px !important;
    height: 35px !important;
  }
  .ant-card-body {
    padding: 15px;
  }
}

@media only screen and (min-width: 769px) {
  .ant-select-lg.ant-select.ant-select-enabled {
    width: 210px;
    font-size: 16px;
  }
  .ant-layout-sider {
    position: relative;
    z-index: 1;
    height: inherit;
  }
  .ant-layout-header {
    text-align: initial;
  }
}

/* ENTITY TOGGLE */

.largeToggle.ant-switch::after {
  height: 32px;
  width: 32px;
}

.ant-switch-checked {
  background-color: var(--main-street-yellow);
}

.largeToggle .ant-switch-inner {
  line-height: 1.2;
}

/* navigation  */

.ant-menu {
  background: var(--light-gray);
  border: none;
}

.ant-menu-item {
  font-weight: 600;
  color: var(--nav-text);
}

.ant-menu-item > a {
  font-weight: 600;
  color: black;
}

.ant-menu-item-active:not(.ant-menu-item-selected) svg {
  fill: var(--ant-blue);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  color: var(--main-street-yellow);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a .customNavIcon {
  fill: var(--main-street-yellow);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:after {
  display: none;
}

.ant-typography code {
  background: var(--nav-text);
}

.ant-typography {
  color: var(--main-blue);
}

.ant-menu-submenu-title {
  font-weight: 600;
  color: var(--nav-text);
}

.ant-menu-submenu > .ant-menu { 
  background: var(--nav-blue);
} 

.location-nav-text {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.nav-text a {
  color: black;
}

/* DISABLED TABLE ROW */

.disabled-row {
  pointer-events: none;
  background-color: red;
}