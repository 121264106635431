.title {
  font-weight: 600;
  letter-spacing: 0.32px;
  color: #424242;
}
.sectionTitle {
  color: var(--main-blue);
  text-transform: uppercase;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 20px;
  letter-spacing: 0.2px;
}
.cardTitle {
  color: var(--main-blue);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  margin-bottom: 24px;
}
.blueCardTitle {
  padding-top: 10px;
  color: var(--main-street-yellow);
  font-weight: 800;
  font-size: 28px;
  text-transform: uppercase;
  text-align: left;
}
.editableTitle{
  color: black;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 24px;
}
.errorText {
  color: red;
}
