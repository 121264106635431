.helpText {
  color: blue;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: underline;
}

.errorText {
  font-size: 16px;
  color: red;
}

.sampleImage {
  max-width: 100%;
  max-height: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}
