.declineButton {
  margin-left: 20px;
} 

.entityAvailability {
  text-align: right;
}

.errorText {
  color: red;
  font-size: 16px;
}

.isActiveToggleColumn {
  text-align: right;
}