.textSmall{
  width: 200px;
}
.border{
  border: 1px solid #979797;
}
.numberSmall{
  width: 100px;
}
.input::placeholder {
  color: transparent;
}

/* INPUT */
.input {
  background: transparent;
  width: 100%;
  border-width: 0 0 1px 0 !important;
  border-radius: 0;
}
.input:hover {
  border-width: 0 0 1px 0 !important;
}
.input:focus {
  border-width: 0 0 1px 0 !important;
}

/* EDITABLE TEXT */
.titleMargin {
  margin: 10px 0px 25px 0px;
}
.editMargin {
  margin: 5px 0px 25px 13px;
}

/* SELECT */
.selectLabel {
  font-size: 15px;
  font-weight: 600;
  line-height: 5px;
  text-transform: uppercase;
  color: var(--main-blue);
}

/* CALENDAR */
.calendarWrapper {
  width: 300px;
}
.calendarLabel {
  font-weight: 600;
  text-transform: uppercase;
  color: var(--main-blue);
  margin-bottom: 15px;
}
.calenderHeader {
  font-style: italic;
  color: var(--button-blue);
}

/* LIVE SEARCH */
.searchResults {
  max-height: 200px;
  display: none;
}
.visible {
  display: initial;
}
.searchResult {
  padding: 5px;
  margin-bottom: 3px;
  cursor: pointer;
  border-radius: 3px;
}
.searchContainer {
  width: 100%;
  position: relative;
}
.searchDropdown {
  width: 100%;
  z-index: 3;
  left: 15px;
}
.searchDropdown input:focus + .search-results{
  display:block;
}

/* DATE RANGE */
.endDatePicker {
  margin-left: 20px;
}
.startFieldWrapper {
  margin-bottom: 0;
  display: inline-block;
}
.endFieldWrapper {
  display: inline-block;
}
.errorText {
  color: red;
}
@media only screen and (max-width: 576px) {
  .startFieldWrapper {
    margin-bottom: 30px;
  }
}

/* TIME RANGE */
.timeRangePicker {
  width: 100%;
  min-width: 110px;
}
