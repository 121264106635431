.page-wrapper {
  height: 100vh;
  background-color: #f0f2f5;
}

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-wrapper >div:first-child{
  height: 100%;
}

.logoContainer {
  display: flex;
  justify-content: center;
  top: -35px;
  position: absolute;
}

.logoContainer svg {
  width: 100%;
  max-width: 70px;
  height: auto;
}

.lunchboxLogoContainer svg {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.headerContainer {
  display: flex;
  justify-content: center;
}

.lunchboxLogoContainer {
  border: none;
  background-color: rgb(255, 255, 255);
  display: block;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.login-container {
  box-shadow: 0 3px 6px rgba(0,0,7,0.05), 0 3px 6px rgba(0,0,7,0.05);
  padding: 7% 7% 10% 7%;
  max-width: 650px;
  width: 100vw;
  margin: 40px 20px;
  text-align: center;
}

.link {
  margin-bottom: 50px;
  text-align: right;
}

.loginButton {
  text-align: center;
  background: white;
  width: 80px;
  padding: 10px;
}

.loginButtonWrapper {
  margin-top: 10px;
}

.passwordWrapper {
  margin-bottom: 50px;
}

.emailWrapper {
  margin-bottom: 20px;
}

.loginInput {
  width: 100%;
}