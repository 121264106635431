.striped tbody tr:nth-child(odd) {
  background-color: #FAFAFA;
}
.error {
  background-color: #ffecec !important;
}
.click {
  cursor: pointer;
}
.row  {
  font-weight: 600;
  color: var(--main-blue);
  height: 55px;
}
.table {
  background: white;
}
.rightAlign {
  text-align: right;
}
.ExpandableTable{
  cursor: pointer;
}
.selectedExpandIcon {
  transform: rotate(90deg);
  transition: transform .25s;
}
.expandIcon {
  transition: transform .25s;
}
.inStock {
  border-color: #435FF7;
  color: #435FF7;
  background-color: #F0F4FF;
}
.outOfStock {
  border-color: #F5222D;
  color: #F5222D;
  background-color: #FFE1E2;
}
