.accepted {
  background-color: #EFFFF0;
  border-color: #099113;
  color: #099113;
}
.declined {
  background-color: #FFE1E2;
  border-color: #F5222D;
  color: #F5222D;
}
.open {
  background-color: #F0F4FF;
  border-color: #435FF7;
  color: #435FF7;
}