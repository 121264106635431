.itemContent {
  display: flex;
  justify-content: space-between;
  padding: 0 25%;
} 

.isActiveToggleColumn {
  text-align: right;
}

.errorText { 
  color: red;
}