.inlineText {
  display: inline;
}
.text {
  font-weight: 600;
  color: var(--main-blue);
  font-size: 15px;
  margin-bottom: 5px
}
.label {
  font-weight: 600;
  text-transform: uppercase;
  color: var(--main-blue);
}
