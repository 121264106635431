.button {
  font-family: Open Sans, Helvetica Neue;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-align: center;
  font-size: 15px;
  height: 40px;
  width: 100%;
  border-radius: 3px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
}
.blue {
  background: var(--main-street-yellow);
  border: 1px solid var(--main-street-yellow);
  color: #fff;
}
.blue:hover {
  background: #fff;
  border: 1px solid var(--main-street-yellow);
  color: var(--main-street-yellow);
}
.white {
  background: white;
  border: white;
  color: var(--main-street-yellow);
}
.transparent {
  background: transparent;
  color: var(--main-street-yellow);
  border: 1px solid var(--main-street-yellow);
}
.transparent:hover {
  background: var(--main-street-yellow);
  color: white;
  border: 1px solid var(--main-street-yellow);
}
.extraSmall{
  width: 100px;
}
.small {
  width: 150px;
}
.medium {
  width: 200px;
}
.large {
  width: 400px;
}
.transparentRed {
  border: 1px solid #f5222d;
  color: #f5222d;
  background: white;
}
.transparentRed:hover {
  border: 1px solid #f5222d;
  color: white;
  background: #f5222d;
}
.green {
  border: 1px solid #099113;
  color: white;
  background: #099113;
}
.green:focus {
  border: 1px solid #099113;
  color: white;
  background: #099113;
}
.green:hover {
  border: 1px solid #099113;
  color: #099113;
  background: white;
}
.red {
  background: #f5222d;
  border: 1px solid #f5222d;
  color: #fff;
}
.red:focus {
  background: #f5222d;
  border: 1px solid #f5222d;
  color: #fff;
}
.red:hover {
  color: #f5222d;;
  border: 1px solid #f5222d;
  background: #fff;
}
.marginLeft {
  margin-left: 5px;
}
.borderless {
  border: none;
}
